import React from "react";
import {Link} from 'react-router-dom';
import '../../mystyle/Home.css';
import '../../mystyle/style.css';

const Project = () => {

    return(
        <>
        
        <div className="kranthi text-center pt-5" id="projectSection">
             <h1 className="head display-4">My Projects</h1>
        </div>
         <div className="container  my-5">
        <div className="row">
            <div className="col-12"> 
                <div className="hover-container border shadow-lg" >
                    <Link to="https://bhoomeshv.github.io/vedha-arts/">VBR Arts & Film</Link>
                </div>
            </div>
        </div>
    </div>

    <div className="container  my-5">
        <div className="row">
            <div className="col-6"> 
                <div className="hover-container border shadow-lg" >
                    <Link to="https://github.com/Kranthive/icecream.git">icecream parlour</Link>
                </div>
            </div>
            <div className="col-6"> 
                <div className="hover-container border shadow-lg" >
                    <Link to="https://github.com/Kranthive/electra-project.git">Electra Events</Link>
                </div>
            </div>
        </div>
    </div>

    <div className="container  my-5">
        <div className="row">
            <div className="col-12"> 
                <div className="hover-container border shadow-lg" >
                    <Link to="">Click Here</Link>
                </div>
            </div>
        </div>
    </div>

    <div className="container  my-5">
        <div className="row">
            <div className="col-6"> 
                <div className="hover-container border shadow-lg" >
                    <Link to="">Click Here</Link>
                </div>
            </div>
            <div className="col-6"> 
                <div className="hover-container border shadow-lg" >
                    <Link to="">Click Here</Link>
                </div>
            </div>
        </div>
    </div>

    

        </>
    )
}
export default Project;