import React from "react";
import '../../mystyle/Home.css';
import '../../mystyle/style.css';
import {Link} from 'react-router-dom';

const Home = () => {


    return(
        <>

<div className=" kranthi container  position-relative text-center " id="homeSection" > 
  <img src={`myimg/myvbk.jpg`} className="img-fluid " alt=""/> 
  <div className="position-absolute top-50 start-50 translate-middle text-white"> 
    <h2 className="display-5">Vilasagar Bharath Kranthiveer</h2> 
    <div class="marquee-container " >
        <div class="marquee">
         I'm Full Stack Web Developer
        </div>
        </div >
    <div className="icon">
        <Link to="https://www.linkedin.com/in/vilasagar-bharath-kranthi-veer-98b518321?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
         className="linkedin"><i class="fa-brands fa-linkedin-in"></i></Link>
        <Link to="https://github.com/Kranthive" className="github"><i class="fa-brands fa-github"></i></Link>
        <Link to="https://youtube.com/@kranthiveer4498?si=e9pag6orTrWf9F7g" className="youtube"><i class="fa-brands fa-youtube"></i></Link>
        <Link to="https://x.com/Kranthivee97857?t=dDEnkRjfOBozYX7q_Eo5aQ&s=09" className="twitter"><i class="fa-brands fa-twitter"></i> </Link>
        <Link to="https://www.facebook.com/share/gqhNk7UNVed2ZtKW/?mibextid=qi2Omg" className="facebook"><i class="fa-brands fa-facebook"></i></Link>
        <Link to="https://www.instagram.com/kranthi_veer1530?igsh=MWloMW02ZHUxMmF4cA==" className="instagram"><i class="fa-brands fa-instagram"></i></Link>
        <Link to="https://wa.me/qr/6VZJ7AFHLXLDG1" className="whatsapp"><i class="fa-brands fa-whatsapp"></i> </Link>
    </div>
        
   </div>
   
</div> 
        </>
    )
};
export default Home;
