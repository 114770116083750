import React from "react";
import '../../mystyle/style1.css';
import Home from "./Home";
import About from "./About";
import Resume from "./Resume";
import Contact from "./Contact";
import Project from "./Projects";



const Header = () => {    
    const onNavClick = (id) => {
        const section = document.querySelector('#' + id);
        section.scrollIntoView();

    }
    return(
        <>
         <div className="my-5">
        <div className="menu-wrapper">
            <div className="" >
                <div className="icon-wrapper" onClick={() => onNavClick('homeSection')}>
                    <div className="icon-circle">
                    <i className="fa-solid fa-house" ></i>
                    </div>
                </div>
             
            </div>
            <div className="" >
                <div className="icon-wrapper" onClick={() => onNavClick("aboutSection")} >
                    <div className="icon-circle">
                    <i className="fa-solid fa-user"></i>
                    </div>
                </div>
                
            </div>
            <div className="">
                <div className="icon-wrapper" onClick={() => onNavClick("projectSection")}>
                    <div className="icon-circle">
                    <i className="fa-solid fa-address-card"></i>
                    </div>
                </div>
               
            </div>
            <div className="">
                <div className="icon-wrapper" onClick={() => onNavClick("resumeSection")}>
                    <div className="icon-circle">
                    <i className="fa-solid fa-file"></i>
                    </div>
                    
                </div>
                
            </div>
            <div className="">
                <div className="icon-wrapper" onClick={() => onNavClick("contactSection")}>
                    <div className="icon-circle">
                    <i className="fa-solid fa-phone"></i>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <Home/>
    <About/>
    <Project/>
    <Resume/>
    <Contact/>

        </>
    );   
};
export default Header;