import React from "react";
import '../../mystyle/style.css';



const Resume = () => {


    return(
        <>
        
       <div class="kranthi resume-container shadow-lg  p-3 mb-5 bg-white rounded " id="resumeSection">
        <header class="header">
            <h1 className="head display-5">MY RESUME</h1>
        </header>
        <br/>
        <section class="personal-info">
            <h3 className="text-left">Vilasagar Bharath Kranthiveer</h3>
            <p className="text-left"> I'm interested in combining creativity and technology.
            Passionate about creating interactive and dynamic web Applications</p>
        </section>
        <section class="education">
            <h3 className="text-left">Education</h3>
            <p className="text-left"><strong>Bachelor of Technology</strong></p>
            <p className="text-left">Aurora Technological Research Institute, 2019 - 2023</p>

            <p className="text-left"><strong>Intermediate</strong></p>
            <p className="text-left">Akshara Junior College, 2018 - 2019</p>

            <p className="text-left"><strong>SSC</strong></p>
            <p className="text-left">Bhanu High School, 2016 - 2017</p>
        </section>
      
          
        <section class="skills">
            <h3 className="text-left">Skills</h3>
            <div class="skill">
                <h4 className="text-left">React Js</h4>
                <div class="progress-bar" style={{'width': '60%'}}>60%</div>
            </div>
            <div class="skill">
                <h4 className="text-left">Node.js</h4>
                <div class="progress-bar" style={{'width': '60%'}}>60%</div>
            </div>
            <div class="skill">
                <h4 className="text-left">MySQL</h4>
                <div class="progress-bar" style={{'width': '65%'}}>65%</div>
            </div>
        </section>
    </div>
            
        </>
    )
}
export default Resume;