import React  from 'react';
import "../../mystyle/style.css";
import Swal from 'sweetalert2';


const Contact = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "fbaaf449-d736-49d7-8d2b-2b8ee7907201");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());
    
    if (res.success) {
      Swal.fire({
        title: "Success!",
        text: "Message Sent Successfully",
        icon: "success"
      });
  };
}

    return(
        <>
         
<div className="kranthi text-center pt-5" id="contactSection">
    <h1 className="head display-4">CONTACT ME</h1>
</div>
<br/>
<div className="container">
  <div className="row">
    <div className="col-12 d-block col-sm-6 col-md-8 col-lg-6">
      <div className="container-col border border-light shadow-lg  bg-body rounded ">
      <div className="container p-5">
            <span>
              <h1 className='head display-6'> <i class="fa-solid fa-location-dot"></i> Present Location</h1>
              <p>1-32,Hanuman Nagar, Chaitanyapuri, Dilsukhnagar, Hyderabad, TS, India 500038</p>
            </span>
            <span>
              <h1 className='head display-6'> <i class="fa-solid fa-location-dot"></i> Permanent Address</h1>
              <p>1-5/1, Sunket, Morthad, Nizamabad, TG, India, 503225, <br/>+91 7997405934</p>
            </span>
            <span>
              <h1 className='head display-6'><i class="fa-solid fa-envelope"></i> Email & Phone</h1>
              <p>bharath7286878317@gmail.com <br/>+91 7997405934</p>
            </span>
      </div>
    </div>
  </div>  


    <div className="col-12 col-sm-6 col-md-8 col-lg-6">
      <div className="container border border-light  shadow-lg  bg-body rounded">
      <div className="contact-col pt-5">
          <form onSubmit={onSubmit} >
            <input  type="text" name="name" placeholder="Enter your name" required/>
            <input  type="email" name="email" placeholder="Enter email address" required/>
            <input  type="number" name="Phonenumber" placeholder="Enter your mobile number" required/>
            <textarea  name="message" placeholder="Message" required></textarea>
            <button type="submit" className="btn btn-outline-primary">Send Message</button>
          </form>
          
        </div>
      </div>
    </div>
</div>
</div>
<br/>
</>
)}
 export default Contact;