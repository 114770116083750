
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Header from './components/user/Header';
import About from './components/user/About';
import Contact from './components/user/Contact';
import Resume from './components/user/Resume';
import Home from './components/user/Home';
import Project from './components/user/Projects';


function App() {
  return (
          <>
          <BrowserRouter>
          <Routes>
            <Route path="/" element={<Header/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/resume" element={<Resume/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/project" element={<Project/>}/>
            
            
         
          </Routes>
          </BrowserRouter>
          </> 
  );
}

export default App;
