import React from "react";
import "../../mystyle/style.css"
import {Link} from 'react-router-dom';


const About = () => {
    
    return(
        <>
 
        
  
<div className="kranthi text-center pt-5 mt-5" id="aboutSection">
    <h1 className="head display-4">ABOUT ME</h1>
</div>
    <div className="  container text-center pt-5">
    <p className="font">Hi, I'm Vilasagar Bharath Kranthiveer, I'm interested in combining creativity and technology. Passionate about creating interactive and dynamic web applications, 
I have completed my Graduation in 2023,  later that i started learning on full stack development like HTML, CSS, JavaScript and as well as React, Node APIs with ExpressJs and My SQL
database, in this i worked on two projects so i have completed the projects and its very well done and very well executed that all from my side.
 Below you can see some projects!
    </p>
    </div>

    <div className="container mt-5">
        <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6  ">
                <div className="container">
                   <img src="./myimg/myvbk.jpg" className="w-100" alt="My Profile "/>
                </div>
            </div>

            <div className="col-md-6  col-sm-6 col-lg-6 ">
                <div className="container shadow-lg p-3 mb-5 bg-white rounded">
                    <ul className="list-unstyled">
                        <h3 className="text-center">Software Developer</h3>
                        
                            <li className="text-left"><strong>Name:</strong> VILASAGAR BHARATH KRANTHIVEER</li>
                            <li className="text-left"><strong>Email:</strong> bharath7286878317@gmail.com</li>
                            <li className="text-left"><strong>Mobile:</strong> (+91) 7997405934</li>
                            <li className="text-left"><strong>Date of Birth:</strong> December 15, 2001</li>
                            <li className="text-left"><strong>Degree:</strong> Bachelor of Technology</li>
                            <li className="text-left"><strong>City:</strong> Nizamabad</li>
                            <li className="text-left"><strong>Website:</strong> <Link to="https://vilasagarbharathkranthiveer.in" target="_blank">vilasagarbharathkranthiveer.in</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="row">
            <div className="col-12"> 
            <div className="text-center pt-5">
        <h1 className="head display-4  ">TECHNICAL SKLLS</h1>
    </div>
    <div className="skill col-sm-6 col-lg-12">
        <h4 className="text-left">Html</h4>
            <div className="progress ">
                <div className="progress-bar" role="progressbar" style={{'width': '70%'}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">70%</div>
                </div>
            </div>
                        
    <div className="skill mt-3 col-sm-6 col-lg-12">
        <h4 className="text-left">Css</h4>
            <div className="progress">
                <div className="progress-bar" role="progressbar" style={{'width': '65%'}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100">65%</div>
                </div>
            </div>
                    
    <div className="skill mt-3 col-sm-6 col-lg-12">
        <h4 className="text-left">JavaScript</h4>
            <div className="progress">
                <div className="progress-bar" role="progressbar" style={{'width': '50%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">50%</div>
                </div>
            </div>

    <div className="skill mt-3 col-sm-6 col-lg-12">
        <h4 className="text-left">Bootstrap</h4>
            <div className="progress">
                <div className="progress-bar" role="progressbar" style={{'width': '75%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">75%</div>
                </div>
            </div>

    <div class="skill mt-3 col-sm-6 col-lg-12">
        <h4 className="text-left">React Js</h4>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style={{'width': '60%'}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">60%</div>
                </div>
            </div>

    <div class="skill mt-3 col-sm-6 col-lg-12">
        <h4 className="text-left">Node Js</h4>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style={{'width': '60%'}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">60%</div>
                </div>
            </div>

    <div class="skill mt-3 col-sm-6 col-lg-12">
        <h4 className="text-left">MySQL</h4>
            <div class="progress">
                <div class="progress-bar" role="progressbar" style={{'width': '65%'}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100">65%</div>
                </div>
            </div>

      </div> 
    </div>
</div>
</>
)}
 export default About;